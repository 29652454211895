// extracted by mini-css-extract-plugin
export var active = "header-module--active--6e62a";
export var burgerActive = "header-module--burgerActive--33055";
export var burgerContainer = "header-module--burgerContainer--c4880";
export var burgerLink = "header-module--burgerLink--9e0df";
export var header = "header-module--header--51a6e";
export var headerContainer = "header-module--headerContainer--855d8";
export var headerLinks = "header-module--headerLinks--c7a9b";
export var headerScrolled = "header-module--headerScrolled--b7cae";
export var homeLink = "header-module--homeLink--96228";
export var link = "header-module--link--a939f";